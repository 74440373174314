<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p class='nomargin'><b>На этом занятии нам понадобятся:</b></p>
            <ul>
                <li>Пластилин скульптурный средней твердости, 500 гр</li>
                <li>Шпажки 4 шт</li>
                <li>Стек или нож для резки пластилина</li>
                <li>Трафареты</li>
                <li>Ваши золотые ручки и зоркий взгляд )</li>
            </ul>
        </div>

        <VideoView video-id="2056f867647a4a0b982c575814ec015a"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Изготовить из пластилина заготовки тела, ног, головы.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>